import { Mui, MuiIcons } from "@osu/react-ui";
import { LEARN_MORE, RESOURCE_LABELS, RESOURCE_TYPES } from "../../util/constants"
import WellnessIcon from "../../Assets/Images/Wellness"
import Wellness from "../../Wellness/components/Dashboard/";
import LinkOutSection from "../components/Resources/LinkOutSection";
import { keys } from "./enums";
import { mappings } from "../../styles/icon_colors";
import { Fragment } from "react";
import Staff from "../components/Resources/Tabs/Staff";

const wellness = {
    tab: 0,
    resourceType: RESOURCE_TYPES.WELLNESS,
    label: RESOURCE_LABELS[RESOURCE_TYPES.WELLNESS],
    id: "wellness-tab",
    Icon: WellnessIcon,
    Component: Wellness
}

const studentSections = [
    wellness,
    {
        tab: 1,
        resourceType: RESOURCE_TYPES.SOCIAL,
        label: RESOURCE_LABELS[RESOURCE_TYPES.SOCIAL],
        Icon: MuiIcons.EmojiPeople,
        id: "social-tab",
        Component: () => <LinkOutSection
        action={{ label: <Fragment>
            {LEARN_MORE}
            <Mui.Typography component="span" variant="srOnly"> Student Organizations</Mui.Typography>
        </Fragment> }} 
        to="/student-organizations" 
        heading="Student Organizations" 
        type={keys.STUD_ORGS}
        color={mappings[keys.STUD_ORGS]}
      >
        Ohio State offers over 1,400 student organizations providing a wide range
        of opportunities to get involved, become a leader worth following, build
        organizations worth joining, and leave a lasting legacy.
    </LinkOutSection>
    },
]

const staffSections = [
    {
        tab: 0,
        resourceType: RESOURCE_TYPES.STAFF_FAC,
        label: RESOURCE_LABELS[RESOURCE_TYPES.STAFF_FAC],
        Icon: MuiIcons.Badge,
        id: "staff-tab",
        Component: () => <Staff />
    },
    {
        ...wellness,
        tab: 1
    }
]

const STUDENT = {
    type: "STUDENT",
    heading: "Resources",
    sections: studentSections
}

const STAFF_FAC = {
    type: "STAFF_FAC",
    heading: "Resources",
    sections: staffSections
}

const combinedType = STUDENT.type + "_" + STAFF_FAC.type

let STAFF_FAC_STUDENT = {
    type: combinedType,
    heading: "Resources",
    sections: [
        staffSections[0],
        ...studentSections
    ]
}
STAFF_FAC_STUDENT.sections = STAFF_FAC_STUDENT.sections.map(({ id, ...rest }, index) => ({
    ...rest,
    id: id + combinedType,
    tab: index
}))


export {
    STUDENT,
    STAFF_FAC,
    STAFF_FAC_STUDENT
}